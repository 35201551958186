.card-base {
  width: 23rem;
  background-color: #fff;
  border-radius: 0.75rem;
  color: unset;
  text-decoration: unset;

  &:hover {
    color: unset;
    text-decoration: unset;
  }
}
