$enable-cssgrid: true;

@import "~@bphxd/ds-core-react/lib/scss/bp-core";
@import "./styles/_components";
@import "./styles/_intro-js-theme";

:root {
  --main-bg-color: var(--bs-white);
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/Roboto/Roboto-Light.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/Roboto/Roboto-Regular.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/Roboto/Roboto-Medium.ttf) format("ttf");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/Roboto/Roboto-Bold.ttf) format("ttf");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "bp";
  src:
    url("/fonts/bp/bp.ttf") format("truetype"),
    url("/fonts/bp/bp.woff") format("woff"),
    url("/fonts/bp/bp.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

body {
  font-family: "Roboto";
  font-weight: 400;
  background-color: var(--main-bg-color);
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
div,
p.lead {
  font-weight: 400;
}

p,
p.small {
  opacity: 1;
}

.small {
  font-weight: 400;
}

.no-underline {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-content {
  margin: 0 auto;

  width: 100%;
  min-height: calc(96vh - 150px);
  flex-wrap: nowrap;

  .main {
    width: 73%;
  }
}

.app-container {
  min-height: calc(96vh - 150px);
}

.app-container,
.footer {
  min-width: 768px;
}

.footer {
  margin: 0 auto;
}

.breadcrumb a {
  opacity: 0.8;
  color: rgb(var(--bs-default-rgb));
}

.mega-menu {
  .navbar-nav .nav-item[aria-current="page"] {
    border-bottom: 1px solid rgba(17, 17, 17, 0.9);
  }
}

.navbar.main-nav {
  background-color: var(--main-bg-color);
  padding: 0;
  // max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.navbar {
  padding: 0.5rem 0;

  .nav-button {
    margin-right: 0 !important;
  }
}

.navbar-nav {
  svg.toggle-chevron {
    transition: transform 300ms;
    &.active {
      transform: rotate(-180deg);
    }
  }
}

.navbar-logo-wrapper {
  display: flex;
  align-items: center;
  max-width: 255px;
  margin-right: 32px;

  @include media-breakpoint-down(md) {
    flex-grow: 0;
    margin-right: 1rem;
  }
}

.navbar-breadcrumb {
  display: flex;
  justify-content: center;
  font-family: "Roboto";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: 0.2px;
  color: #111111;
  opacity: 0.74;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

// PageTabs
.nav-tabs {
  .nav-item {
    .nav-link.active {
      background-color: #f9f9f9;
    }
  }
}

// TODO: Flagged styles: NEXT_PUBLIC_SHOW_MEGA_MENU === 'true';
.navbarLeft__items {
  padding-left: 16px;

  .nav-item {
    &:last-child {
      padding-bottom: 10px;
    }
  }

  .nav-section-description {
    font-size: 13px;
    padding: 4px 0 4px 16px;
    color: rgba(17, 17, 17, 0.64);
  }

  .nav-description-link {
    font-size: 13px;
    padding-left: 16px;
    text-decoration: none;

    &:hover {
      color: rgba(0, 43, 188, 1);
    }
  }

  .link-group-header {
    color: rgb(17, 17, 17);
    padding: 12px 0 12px 16px;
    font-size: 12px;
    letter-spacing: 0.9px;
  }

  .nav-links {
    color: rgba(17, 17, 17, 0.64);
    padding: 8px 0 8px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.54;
    font-size: 16px;

    .dac-nav & {
      justify-content: flex-start;
    }

    &:hover {
      text-decoration: none !important;
      color: rgba(17, 17, 17, 1) !important;
    }
  }
}

.navbar-left-col {
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0.8px;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.dac-nav {
    opacity: 0;
    transition: opacity 1s;

    &.is-active {
      opacity: 1;
    }

    .nav-link__top-level {
      span {
        margin-left: 8px;
      }
    }

    .nav-link__back-btn {
      padding-top: 0;
      min-height: auto;
    }

    .navbar-left__arrow {
      &:hover {
        path {
          stroke-width: 2;
        }
      }
    }
  }

  .navbar-left__heading.selected {
    background-color: #fafafa;
  }
  .navbar-left__heading.highlight {
    background-color: #efefef;
  }

  .nav-item {
    width: 100%;
  }

  .nav-link {
    color: rgba(17, 17, 17, 0.64);
    display: flex;
    align-items: center;
    line-height: 1.54;
    font-size: 13px;

    .dac-nav & {
      justify-content: flex-start;
    }

    &:hover {
      text-decoration: none !important;
      color: rgba(17, 17, 17, 1) !important;
    }

    &.selected {
      color: rgba(17, 17, 17, 0.84);
    }
  }
  // TODO: Flagged styles: NEXT_PUBLIC_SHOW_MEGA_MENU === 'true';
  .navBar-left__section {
    border-bottom: 1px solid rgba(17, 17, 17, 0.2);

    .home-link {
      color: rgba(17, 17, 17, 0.7);
      &:hover {
        color: rgba(17, 17, 17);
      }
    }

    .navBarLeft__heading {
      font-size: 1rem;
      color: rgba(17, 17, 17, 0.7);
      &:hover {
        color: rgba(17, 17, 17);
      }
    }
  }

  // TODO: Flagged styles: NEXT_PUBLIC_SHOW_MEGA_MENU === 'true';
  .navBarLeft__heading {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: rgba(17, 17, 17, 0.64);
    min-height: 48px;

    .navbar-left__title {
      line-height: 1.54;
      flex-grow: 1;
      padding-left: 10px;
      display: flex;
      align-items: center;
      font-size: 13px;
      text-transform: uppercase;

      &.navbar-left__indented {
        width: 100%;
      }
    }

    // TODO: Flagged styles: NEXT_PUBLIC_SHOW_MEGA_MENU === 'true';
    .navBarLeft__title {
      line-height: 1.54;
      flex-grow: 1;
      padding-left: 5px;
      display: flex;
      align-items: center;
      font-size: 16px;

      &.navbar-left__indented {
        width: 100%;
      }
    }

    &:hover {
      .navbar-left__title {
        color: rgba(17, 17, 17, 1);
      }
    }

    .navbar-left__icon {
      opacity: 0.54;
    }

    &:hover {
      .navbar-left__icon {
        opacity: 1;
      }
    }

    .navbar-left__arrow {
      opacity: 0.54;
      &.down-arrow {
        transform: rotate(180deg);
      }
    }

    &:hover {
      .navbar-left__arrow {
        transition: transform 300ms;
        opacity: 1;
      }
    }

    .navbar-left32__arrow {
      opacity: 0.54;
      color: rgba(17, 17, 17, 0.7);
      &.down-arrow {
        transform: rotate(90deg);
      }
    }

    &:hover {
      .navbar-left32__arrow {
        transition: transform 300ms;
        color: rgba(17, 17, 17);
        opacity: 1;
      }
    }
  }

  // TODO: Flagged styles: NEXT_PUBLIC_SHOW_MEGA_MENU === 'true';
  .nav-items {
    width: 100%;
    color: rgba(17, 17, 17, 0.64);
    padding-left: 20px;
  }
}

.navbar-main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbar-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navbar-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-avatar-wrapper {
  display: flex;
  justify-content: center;
  width: 44px;
}
.navbar-avatar {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  &.user-image {
    width: min-content;
    border: none;
    margin: auto;

    &.is-profile-page {
      padding: 1px;
      border: solid 1px rgba(17, 17, 17, 0.84);
    }

    &:hover {
      padding: 2px;
      border: solid 2px #111;
    }
  }

  &.avatar-icon {
    width: min-content;
    margin: auto;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  img,
  svg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: solid 1px white;
  }
}

.navbar-cta {
  margin-left: 40px;
}

.close-btn {
  cursor: pointer;
}

.section-heading {
  display: flex;
  margin: 0 15px;
  justify-content: space-between;
}

.read-only {
  position: absolute;
  left: -1000px;
}

.section-heading__cta {
  padding: 0;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: 0.2px;
  text-align: right;
  color: #002bbc;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.page-section__list-view {
  .page-section__cell {
    border-top: 1px solid #d8d8d8;

    .link {
      color: #111111;
    }
  }
  .info-group {
    display: flex;

    .details {
      flex: 2;
      margin-right: 15px;
    }

    .subTitle {
      flex: 1;
      margin-left: 15px;
    }
  }
}

.page-section__grid-view {
  display: flex;
  flex-wrap: wrap;
}

.reset-anchor {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

select {
  font-family: Arial;
  option[value=""] {
    color: hsl(0, 0%, 50%);
  }
}

.override-accordion-button {
  .accordion-button {
    padding: 0;
    background-color: transparent;
    border: 0;
    &::after {
      align-self: center;
    }
  }
}

.accordion-button-self-start {
  .accordion-button::after {
    align-self: start;
  }
}

.accordion-body {
  font-size: unset;
}

.border-no-color {
  border-width: var(--bs-border-width);
  border-style: var(--bs-border-style);
}

.text-danger-dark {
  color: #a80000;
}

.border-danger-dark {
  border-color: #a80000 !important;
}

.bg-danger-light {
  background-color: #e64949 !important;
}

.bg-success-light {
  background-color: #15ad5c !important;
}

.fs-7 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-8 {
  font-size: 10px !important;
}

.fs-0 {
  font-size: 0 !important;
}

.w-fit {
  width: fit-content;
}

.w-max-content {
  width: max-content;
}

.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-24px {
  width: 24px;
}
.w-72px {
  width: 72px;
}

.max-w-fit {
  max-width: fit-content;
}

.min-w-50 {
  min-width: 50%;
}
.min-w-100 {
  min-width: 100%;
}

.h-min-content {
  height: min-content;
}

.min-h-100 {
  min-height: 100%;
}

.clickable,
.cursor-pointer {
  cursor: pointer;
}

.not-allowed,
.cursor-not-allowed {
  cursor: not-allowed;
}

.bounce {
  animation: bounce 2s ease infinite;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-2px);
  }
}

.full-opacity-on-hover {
  opacity: 80%;
  &:hover {
    opacity: 100%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.list-style-type-none {
  list-style-type: none;
}

.lh-2-5 {
  line-height: 2.5;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:show-scrollbar {
  &:hover::-webkit-scrollbar {
    width: thin; /* Show the scrollbar on hover */
  }
  &:hover {
    overflow-y: auto; /* Show scrollbar space on hover */
    scrollbar-width: thin;
    padding-right: 0;
  }
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    width: 0; /* Hide the scrollbar by default */
  }
}

.last-child\:border-bottom-0:last-child {
  border-bottom: 0 !important;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1; /* Standard property for future compatibility */
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2; /* Standard property for future compatibility */
  overflow: hidden;
}

@mixin truncate-lines($lines) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-1-line {
  @include truncate-lines(1);
}

.truncate-2-lines {
  @include truncate-lines(2);
}

.truncate-3-lines {
  @include truncate-lines(3);
}

.line-clamp-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-clamp: 4; /* Standard property for future compatibility */
}

.text-overflow-clip {
  text-overflow: clip;
}

.h-1px {
  height: 1px;
}
.h-24px {
  height: 24px;
}
.h-72px {
  height: 72px;
}
.h-75vh {
  height: calc(75vh);
}

.box-shadow-top {
  box-shadow: 0 -10px 20px -6px rgba(35, 35, 35, 0.08);
}

.sticky-bottom {
  bottom: 0;
  position: sticky;
  z-index: 0;
}

.scroll-behavior-smooth {
  scroll-behavior: smooth;
}

.webkit-font-smoothing-none {
  -webkit-font-smoothing: none;
}

.moz-osx-font-smoothing-auto {
  -moz-osx-font-smoothing: auto;
}

.resize-none {
  resize: none;
}

.toolbar-z-index-0 {
  div[role="toolbar"] {
    z-index: 0;
  }
}

//targets the mdx component selector to override the width
[class*="toolbarButtonDropdownContainer"] {
  width: 18rem !important;
  max-height: 75vh !important;
}

.loading-fade {
  animation: loading-fade 1s infinite;
}
@keyframes loading-fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

.khub-navtree {
  min-height: 40px;
  padding-inline-end: 8px;
  .kebab-icon-hidden {
    visibility: hidden;
  }
  .kebab-icon-visible {
    visibility: visible;
  }
  &:hover {
    .kebab-icon {
      visibility: visible;
    }
  }
}

.khub-feedback-popover {
  height: 7.5rem !important;
  border: solid 1px #404040;
  line-height: 1.2 !important;
  padding-top: 12px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.64) !important;
    margin-left: 15px;
  }
}

.khub-toc-link {
  &.active {
    background-color: var(--bs-light) !important;
  }

  &:hover {
    background-color: var(--bs-black) !important;
    color: var(--bs-white) !important;
  }

  :focus {
    border: 1px solid blue;
  }

  .bullet {
    color: #979797 !important;
    min-width: 16px !important;
  }

  .bullet.active {
    color: var(--bs-black) !important;
  }

  &:hover > .bullet {
    color: var(--bs-white) !important;
  }

  &:active > .bullet {
    color: var(--bs-black) !important;
  }
}

.negate-header-margin-top {
  h1:first-of-type {
    margin-top: 0;
  }
}

.accordion-section-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
  @media (min-width: 1440px) {
    grid-template-columns: 1fr 1fr;
  }
}
