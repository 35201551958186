.card-title {
  width: 207px;
  /* height: 28px; */
  margin: 0 0 8px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.1px;
  color: #111;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-copy {
  width: 207px;
  /* height: 69px; */
  /* margin: 8px 0 0; */
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: 0.25px;
  color: #111;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-author {
  width: 168px;
  height: 21px;
  margin: 3px 0 5px 10px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.2px;
  color: #111;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-background {
  width: 255px;
  height: 206px;
  padding: 24px;
  border-radius: 8px;
  border: solid 1px #d8d8d8;
  background-color: #fff;
}

.loading-card {
  width: 23rem;
  background-color: #fff;
  border-radius: 0.75rem;
  border-radius: 8px;
  border: solid 1px #d8d8d8;
  margin: 0 10px 10px 0;
}
