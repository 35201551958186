.container {
  background: rgba(52, 52, 52, 0.8);

  &.fadeIn {
    visibility: visible;
    opacity: 1;
    transition:
      visibility 0s linear 0s,
      opacity 300ms;
  }

  &.fadeOut {
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s linear 300ms,
      opacity 300ms;
  }
}
