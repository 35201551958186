.length-counter {
  font-size: 11px;
  float: right;
  color: #0f0f0f;
  margin-top: 8px;
}

.input {
  &[type="radio"] ~ label {
    cursor: pointer;
  }
}

.error-message {
  font-size: 12px;
  color: #a80000;
  float: left;
  min-height: 24px;
}
