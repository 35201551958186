div.step-circle {
  text-align: center;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  margin: 0 1rem 0 0;
  border: 1px solid black;
  border-radius: 999%;
  line-height: 1.4;
}
